import React from 'react';
import './style.scss';
const Splash: React.FC = () => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="loadingio-spinner-pulse-dvkh3f5tq">
        <div className="ldio-cnruza7t4zc">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Splash;

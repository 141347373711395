import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface Props {
  label: string;
  description;
  defaultValue: boolean;
  output: string;
  handleChangeData: (field, value) => void;
}

const CheckBox: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState(props.defaultValue);

  return (
    <div>
      <div className="input-group mb-3">
        <div className="input-group-prepend mr-2">
          <span
            className="input-group-text"
            data-toggle="tooltip"
            data-placement="top"
            title={props.description}
            id="inputGroup-sizing-default">
            {props.label}
          </span>
        </div>
        <FontAwesomeIcon
          icon={checked ? faToggleOn : faToggleOff}
          style={{ fontSize: '35px' }}
          onClick={() => {
            props.handleChangeData(props.output, checked ? false : true);
            setChecked(!checked);
          }}
        />
      </div>
    </div>
  );
};

export default CheckBox;

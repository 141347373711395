const dev = {
  API_URL: 'http://localhost:3010',
  // API_URL: 'http://192.168.10.129:3010',
  // API_URL: 'https://gameserver.hoclieu.vn',
  // API_URL: 'https://minigames.saokhuee.com',
};

const prod = {
  // API_URL: 'https://gameserver.hoclieu.vn',
  API_URL: 'https://minigames.saokhuee.com',
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default config;

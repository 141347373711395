import API from '../../utils/api';

const URL_PREFIX = '/api/history';

export const historyService = {
  getHistory,
  deleteHistory,
};

function getHistory(data: { gameId: string }) {
  return API.post(`${URL_PREFIX}/getHistory`, data);
}
function deleteHistory(data: { index: number; gameId: string }) {
  return API.post(`${URL_PREFIX}/deleteHistory`, data);
}

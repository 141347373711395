import axios, { AxiosResponse } from 'axios';
import config from '../config';
import { resData } from '../electron/offline';
// import { configureFakeBackend } from '../services/fake-backend';

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  responseType: 'json',
});

const requestHandler = (request) => {
  if (window.electronAPI) {
    request.adapter = (requestConfig) => {
      return new Promise(async (resolve, reject) => {
        const res: AxiosResponse = {
          data: {},
          status: 200,
          statusText: 'OK',
          headers: { 'content-type': 'application/json' },
          config: requestConfig,
          request: {},
        };

        res.data = await resData(requestConfig);
        return resolve(res);
      });
    };
  }

  // let valueToken = document.cookie.substring(6);
  let valueToken = localStorage.getItem("token");
  // Thêm token vào header nếu user vẫn tồn tại
  request.headers['x-access-token'] = valueToken;
  return request;
};

const successHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  return Promise.reject({ ...error });
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

// configureFakeBackend(axiosInstance);

export default axiosInstance;

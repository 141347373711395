import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { historyService } from '../../../services/history/api';
import { Dropdown } from 'react-bootstrap';

interface Props {
  game: any;
  setGame: (data) => void;
  reload: number;
  setReload: (reload: number) => void;
}

const HistoryForm: React.FC<Props> = (props: Props) => {
  const [history, setHistory] = useState<{
    gameId: string;
    history: Array<{
      userId: string;
      email: string;
      data: Object;
      time: string;
    }>;
    createdAt: string;
    updatedAt: string;
  }>();
  useEffect(() => {
    if (props.game?._id) {
      historyService
        .getHistory({ gameId: props.game?._id })
        .then((res) => {
          setHistory(res.data.data);
        })
        .catch((err) => {
          toast.error('Lỗi khi lấy dữ liệu history!');
        });
    }
  }, [props.game, props.reload]);

  return (
    <div className="container mt-5" style={{ height: '500px', overflow: 'auto' }} key="HistoryForm">
      <div className="row">
        <h2 className="col font-weight-bold">History</h2>
      </div>
      <button
        type="button"
        className="btn btn-primary mb-3"
        onClick={() => {
          props.setReload(props.reload + 1);
        }}>
        reload
      </button>
      <div className="row mb-3">
        <div className="col font-weight-bold">STT</div>
        <div className="col font-weight-bold">Email/Name</div>
        <div className="col font-weight-bold">Ngày</div>
        <div className="col font-weight-bold">Giờ</div>
        <div className="col font-weight-bold">Action</div>
      </div>
      {history?.history?.reverse()?.map((item, i) => (
        <div className="row mt-2" key={`item-${i}`}>
          <div className="col">{i + 1}</div>
          <div className="col">{item.email}</div>
          <div className="col">
            {new Date(item.time).getDate()}/{new Date(item.time).getMonth() + 1}/{new Date(item.time).getFullYear()}
          </div>
          <div className="col">
            {new Date(item.time).getHours()}:{new Date(item.time).getMinutes()}:{new Date(item.time).getSeconds()}
          </div>
          <div className="col">
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    props.setGame(item.data);
                  }}>
                  Use data
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(item.data));
                    toast('Copy thành công');
                  }}>
                  Copy data
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    let check = window.confirm('Bạn có muốn xóa lịch sử này?');
                    if (check) {
                      historyService
                        .deleteHistory({ index: history.history.length - 1 - i, gameId: props.game?._id })
                        .then((res) => {
                          setHistory(res.data.data);
                          toast.success('Thành công!');
                        })
                        .catch((err) => {
                          toast.warning('Bạn không có quyền xóa lịch sử này!');
                        });
                    }
                  }}
                  className="text-danger">
                  Delete history
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ))}
    </div>
  );
};
export default HistoryForm;

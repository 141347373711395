import React from 'react';

interface Props {
  className?: string;
  name: string;
  data: any;
  option?: any;
  disabled?: boolean;
  onchange?: (op) => void;
}

const Dropdown: React.FC<Props> = (props) => {
  // const [name, setAnswer] = useState('');
  return (
    <div>
      <select
        className={props.className}
        name={props.name}
        onChange={(op) => props.onchange(op)}>
        {props.data.map((element) => {
          return <option value={element.value}>{element.label}</option>;
        })}
      </select>
    </div>
  );
};

export default Dropdown;

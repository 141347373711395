/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import DynamicForm from '../../../form/dynamic-form';
import 'bootstrap/dist/css/bootstrap.css';
import { toast } from 'react-toastify';
import { gameService } from '../../../services/game/api';
import HistoryForm from '../History';
import { xlsParser } from '../../../services/game/xlsParser';
import { extractResources, Result } from '../../../services/game/formatGameData';

const FormInput = (props: {
  gameTitle;
  _gameId;
  unitId;
  error_warning: Result;
  setError_warning: React.Dispatch<React.SetStateAction<Result>>;
  onClick?: () => void;
}) => {
  const { gameTitle, _gameId, unitId, onClick } = props;
  const [game, setGame] = useState(undefined);
  const [reload, setReload] = useState(0);

  const gameData = useRef('');
  const ref_link_excel = useRef(null);

  useEffect(() => {
    if (_gameId) {
      getGame();
    } else {
      setGame('');
    }
    ref_link_excel.current.value = '';
  }, [_gameId]);

  useEffect(() => {
    setGame('');
  }, [unitId]);

  const form = [
    [
      {
        type: 'uploadzone',
        width: '100%',
        height: 50,
        placeHolder: 'Kéo thả các file excel vào đây HOẶC Nhấn vào đây để chọn file.',
        accept: {
          'application/vnd.ms-excel': ['.xls', '.xlsx'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
        },
        onDrop: (files: File[]) => {
          xlsParser
            .parse(files)
            .then((newJson) => {
              extractResources(newJson.data.resource, null).then((result) => {
                if (result.duplicatedKeys.length > 0) {
                  props.setError_warning(result);
                  toast.error('Có một số bản ghi trùng id, vui lòng kiểm tra lại!');
                  return;
                }
                if (result.duplicatedPaths.length > 0) {
                  toast.warning('Có một số bản ghi trùng path, vui lòng kiểm tra lại!');
                }

                newJson.data.resource = result.resources;

                const json =
                  gameData.current !== '' ? { ...JSON.parse(gameData.current), ...newJson } : { ...game, ...newJson };
                gameData.current = JSON.stringify(json);
                setGame(json);
                props.setError_warning(result);

                toast.success('Nhập excel thành công! Nhấn Cập nhật/Tạo mới để lưu.');
              });
            })
            .catch((error) => {
              toast.error(error);
            });
        },
      },
      {
        type: 'title',
        label: _gameId ? `Cập nhật  ${gameTitle}` : 'Tạo Game',
      },
      {
        type: 'editor',
        change: (e) => {
          onChangeEditor(e);
        },
        width: '100%',
        height: 600,
        value: game,
      },
    ],
  ];

  const getGame = () => {
    if (_gameId) {
      gameService.getGame(_gameId).then((res) => {
        if (res.data.status === 200) {
          // toast.success(res.data.message);
          setGame(res.data.data);
          ref_link_excel.current.value = res.data.data.linkDataExcel ?? '';
          if (!res.data.data.linkDataExcel) {
            toast.warning('Game chưa có data excel.');
          }
        } else {
          toast.error(res.data.message);
        }
      });
    }
  };

  const addGameProd = () => {
    if (gameData.current !== '') {
      let data = JSON.parse(gameData.current);
      data.unitId = unitId;
      gameService
        .addGameProd(data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || 'Không có quyền truy cập');
        });

      gameData.current = '';
    }
    setTimeout(() => {
      onClick();
    }, 200);
  };

  const updateGameProd = () => {
    if (gameData.current !== '') {
      gameService
        .updateGameProd({ game_data: JSON.parse(gameData.current), describe: '' })
        .then((res) => {
          toast.success(res.data.message);
          setReload(reload + 1);
        })
        .catch((err) => {
          toast.error('Cập nhật thất bại!');
        });
      setTimeout(() => {
        getGame();
      }, 200);

      gameData.current = '';
    }
  };

  const deleteGameProd = () => {
    if (_gameId) {
      gameService
        .deleteGameProd(_gameId)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message || 'Một lỗi không mong muốn đã xảy ra');
        });
      setTimeout(() => {
        setGame(undefined);
        onClick();
      }, 200);
    }
  };
  // const deleteGame = () => {
  //   if (_gameId) {
  //     gameService.deleteGameProd(_gameId).then((res) => {
  //       if (res.data.status === 200) {
  //         toast.success(res.data.message);
  //       } else {
  //         toast.error(res.data.message);
  //       }
  //     });
  //     setTimeout(() => {
  //       onClick();
  //     }, 200);
  //   }
  // };

  const onChangeEditor = (e) => {
    gameData.current = e;
    ref_link_excel.current.value = e.linkDataExcel ?? '';
  };

  return (
    <div className="mt-3">
      <div className="px-3">
        {form.map((fields, index) => {
          return <DynamicForm key={index} fields={fields} />;
        })}
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={(e) => {
                window.open(ref_link_excel.current.value, '_blank');
              }}>
              Truy cập
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder=""
            aria-label=""
            aria-describedby="basic-addon1"
            ref={ref_link_excel}
            onChange={(e) => {
              const json =
                gameData.current !== ''
                  ? { ...JSON.parse(gameData.current), linkDataExcel: ref_link_excel.current.value ?? null }
                  : { ...game };
              gameData.current = JSON.stringify(json);
              setGame(json);
            }}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={(e) => {}}>
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="text-right">
        {_gameId ? (
          <button
            className="btn btn-danger mr-2"
            onClick={() => {
              let confirm = window.confirm('Bạn có muốn xóa game này!');
              if (confirm) {
                deleteGameProd();
              }
            }}>
            Xoá game
          </button>
        ) : null}
        <button
          className="btn btn-primary"
          onClick={() => {
            _gameId ? updateGameProd() : addGameProd();
          }}>
          {_gameId ? 'Cập nhật' : 'Lưu'}
        </button>
      </div>
      <HistoryForm game={game} setGame={setGame} reload={reload} setReload={setReload}></HistoryForm>
    </div>
  );
};

export default FormInput;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const Menu = (props: { open; onClick: () => void; grades; setGrade: (item) => void; setOpen: () => void }) => {
  const { open, onClick, grades, setGrade, setOpen } = props;

  return (
    <>
      <StyledMenu openMenu={open}>
        <Button className="mr-2 btn-add" variant="primary" onClick={() => onClick()}>
          Tạo mới
        </Button>
        {grades.map((item, index) => {
          const { title } = item;
          return (
            <a
              href="#"
              key={index}
              onClick={() => {
                setGrade(item);
                setOpen();
              }}>{`${title}`}</a>
          );
        })}
      </StyledMenu>
    </>
  );
};
const StyledMenu = styled.nav`
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: #ffffff;
  transform: ${({ openMenu }) => (openMenu ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  padding-top: 5rem;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  overflow-y: scroll;
  position: fixed;
  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 2rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #292828;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  a:hover {
    color: #878686;
  }
`;
export default Menu;

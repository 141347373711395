import React, { useState } from 'react';
import './style.scss';
interface Props {
  option: Array<any>;
  label?: string;
  description?: string;
  isImage?: boolean;
  handleChangeResource?: (field, value) => void;
}

const Resource: React.FC<Props> = (props) => {
  const [resource, setResource] = useState(props.option?.[0]);
  return (
    <div className="select-box">
      <div className="wrapper input-group mb-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            data-toggle="tooltip"
            data-placement="top"
            title={props.description}
            id="inputGroup-sizing-default">
            {props.label}
          </span>
        </div>
        <select className="form-select" onChange={(e) => setResource(e.target.value)}>
          {props.option?.map((element) => {
            return <option value={element}>{element}</option>;
          })}
        </select>
        <input type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
        <i className="fa fa-volume-up"></i>
        <button
          className="btn btn-primary mr-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Tooltip on top"
          type="button">
          Add
        </button>
        {!props.isImage && (
          <button
            className="btn btn-info mr-3"
            data-toggle="tooltip"
            data-placement="top"
            title="Tooltip on top"
            type="button">
            Play
          </button>
        )}
      </div>
      {props.isImage && <img src={resource} alt="" />}
    </div>
  );
};

export default Resource;

import React, { useState } from 'react';
import FieldBuilder from '..';

interface Props {
  label: string;
  description: string;
  form: Array<any>;
  format: string;
  handleChangeData?: (field, value) => void;
  handleChangeResource?: (field, value) => void;
}

const Container: React.FC<Props> = (props) => {
  const [index, setIndex] = useState(0);
  return (
    <div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            data-toggle="tooltip"
            data-placement="top"
            title={props.description}
            id="inputGroup-sizing-default">
            {props.label}
          </span>
        </div>
        <div className="input-group-prepend">
          <button
            className="btn btn-primary"
            data-toggle="tooltip"
            data-placement="top"
            title="Tooltip on top"
            type="button"
            onClick={() => {
              setIndex(index + 1);
              console.log(index + 1);
            }}>
            Add
          </button>
        </div>
      </div>
      <div className="ml-4">
        {props?.form &&
          Object.keys(props.form).map((key, i) => {
            return (
              <FieldBuilder
                key={i}
                data={props.form[key]}
                format={props.format?.replace('$', index.toString())}
                handleChangeData={props.handleChangeData}
                handleChangeResource={props.handleChangeResource}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Container;

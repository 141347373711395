import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import React from 'react';
import { Result } from '../../../services/game/formatGameData';

interface Props {
  result: Result;
}
const ErrorWarning: React.FC<Props> = ({ result }: Props) => {
  return (
    <div className="error_warning">
      <div className="col-lg-12">
        <div className="error">
          <ul>
            {result && result.errors.length > 0 ? (
              <div className="card mb-1" key={0}>
                <div className="card-header bg-danger text-light">
                  <b>Error path file package</b>
                </div>
                <div className="card-body">
                  {result.errors.map(({ file, statusText }, index) => {
                    return (
                      <div className="card-text" key={index}>
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(file);
                          }}>
                          {`${file}: ${statusText}`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </ul>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="error">
          <ul>
            {result && result.duplicatedKeys.length > 0
              ? result.duplicatedKeys.map(({ file, key }, index) => {
                  return (
                    <div className="card mb-1" key={index}>
                      <div className="card-header bg-danger text-light">
                        <b onClick={() => navigator.clipboard.writeText(file)}>{file}</b>
                      </div>
                      <div className="card-body">
                        <div className="card-text">
                          <span onClick={() => navigator.clipboard.writeText(key)}>{key}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="warning">
          <ul>
            {result && result.duplicatedPaths.length > 0
              ? result.duplicatedPaths.map(({ file, path }, index) => {
                  return (
                    <div className="card mb-1" key={index}>
                      <div className="card-header bg-warning text-light">
                        <b onClick={() => navigator.clipboard.writeText(file)}>{file}</b>
                      </div>
                      <div className="card-body">
                        <div className="card-text">
                          <span onClick={() => <b onClick={() => navigator.clipboard.writeText(path)}>{path}</b>}>
                            {path}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ErrorWarning;

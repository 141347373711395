import React, { useCallback } from 'react';
import './style.scss';

import { useDropzone, Accept } from 'react-dropzone';

interface Props {
  accept: Accept;
  onDrop: Function;
  placeHolder: string;
  [key: string]: any;
}

const UploadZone: React.FC<Props> = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.onDrop(acceptedFiles);
    },
    [props],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
  });

  return (
    <div {...getRootProps()} style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', ...props.style_div }}>
      <input style={{ ...props.style_input }} {...getInputProps()} />
      <p style={{ margin: '1rem', ...props.style_p }}>{props.placeHolder}</p>
    </div>
  );
};

export default UploadZone;

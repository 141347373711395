import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, HashRouter, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Splash from './layout/Splash';
import Home from './scenes/Home';
import store from './services/store';

const Login = lazy(() => import('./login/index'));
const Signup = lazy(() => import('./signup/index'));
const Game = lazy(() => import('./scenes/Game'));
// const FormInput = lazy(() => import('./scenes/FormInput'));
const FormDataDetail = lazy(() => import('./scenes/FormDataDetail'));
// const TranscriptionComponent = lazy(() => import('./scenes/FormTranscription/index'));
// const TestPixiGAF = lazy(() => import('./scenes/TestPixiGAF'));
const SocketioClient = lazy(() => import('./scenes/SocketioClient'));
// const TestLottie = lazy(() => import('./scenes/TestLottie'));
const TestPaint = lazy(() => import('./scenes/TestPaint'));
const TestFrame = lazy(() => import('./scenes/TestFrame'));
const FlatFormPair = lazy(() => import('./scenes/FlatFormDetail/pair'));
const FlatFormQuestion = lazy(() => import('./scenes/FlatFormDetail/question'));
const FlatForm = lazy(() => import('./scenes/FlatForm'));
const Search = lazy(() => import('./home_page/games/search_games/Search'));
const EditData = lazy(() => import('./home_page/games/edit_data/EditData'));
const HomePage = lazy(() => import('./home_page/index'));
const UserWait = lazy(() => import('./user-wait/index'));
const DevGame = lazy(() => import('./home_page/games/dev_games/DevGame'));
const EditorFromPath = lazy(() => import('./home_page/tools/editor/EditorFromPath'));
const ScriptsFromPath = lazy(() => import('./home_page/tools/scripts/ScriptsFromPath'));
const UsersFromPath = lazy(() => import('./home_page/users/UsersFromPath'));
const TranscriptFromPath = lazy(() => import('./home_page/tools/transcript/TranscriptFromPath'));

const Router = window.electronAPI ? HashRouter : BrowserRouter;

const App: React.FC = () => {
  return (
    <Provider store={store({})}>
      <Router>
        <Suspense fallback={<Splash />}>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/games/:gameId" element={<Game />} />
            {/* <Route path="/dev" element={<FormInput />} /> */}
            {/* <Route path="/dev/:gameId" element={<FormInput />} /> */}
            <Route path="/data/:gameId" element={<FormDataDetail />} />
            <Route path="/transcript" element={<TranscriptFromPath />} />
            {/* <Route path="/testpixigaf" element={<TestPixiGAF />} /> */}
            <Route path="/socketio" element={<SocketioClient />} />
            {/* <Route path="/testlottie" element={<TestLottie />} /> */}
            <Route path="/testpaint" element={<TestPaint />} />
            <Route path="/testframe" element={<TestFrame />} />
            <Route path="/flatform/pair" element={<FlatFormPair />} />
            <Route path="/flatform/question" element={<FlatFormQuestion />} />
            <Route path="/flatform" element={<FlatForm />} />
            {/* <Route path="/users" element={<Users />} /> */}
            <Route path="/user-wait" element={<UserWait />} />
            <Route path="/search" element={<Search />} />
            <Route path="/search-game" element={<Search />} />
            <Route path="/edit_data" element={<EditData />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/home/:path" element={<HomePage />} />
            <Route path="/home/:path/:id" element={<HomePage />} />
            <Route path="/dev" element={<DevGame />} />
            <Route path="/dev/:id" element={<DevGame />} />
            <Route path="/editor" element={<EditorFromPath />} />
            <Route path="/script" element={<ScriptsFromPath />} />
            <Route path="/users" element={<UsersFromPath />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
    </Provider>
  );
};

export default App;

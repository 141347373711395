import React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

const Modals = (props: {
  show;
  setShow: (e) => void;
  isCreUnit;
  unitName;
  setUnitName: (e) => void;
  folderName;
  setFolderName: (e) => void;
  handleSaveUnit: () => void;
  handleSaveGrade: () => void;
}) => {
  const {
    show,
    setShow,
    isCreUnit,
    unitName,
    setUnitName,
    folderName,
    setFolderName,
    handleSaveUnit,
    handleSaveGrade,
  } = props;
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Tạo thư mục</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
          Tên Thư Mục:
        </label>
        {isCreUnit ? (
          <input name="Unit" type="text" value={unitName} onChange={(e) => setUnitName(e.target.value)} required />
        ) : (
          <input name="Grade" type="text" value={folderName} onChange={(e) => setFolderName(e.target.value)} required />
        )}
        <></>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            isCreUnit ? handleSaveUnit() : handleSaveGrade();
          }}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Modals;
